<template>
 <!--CTA Section-->

    <div class="cta-area-2 white">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-12">
                    <h6>Pristup udruženju</h6>
                    <h2>Želite pristupiti <b>udruženju</b> ?</h2>
                </div>
                <div class="col-lg-6 offset-lg-1 col-md-12 col-12">
                    <div class="contact-info">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <a href="/clanstvo" class="main-btn cta-btn">Postani član udruženja</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
export default {
    name: 'CTA',
    data(){
        return {
            homeData : []
        }
    },
    async mounted(){
       
        let fetchedHomeData = await axios.get("/assets/data/homeData.json");
        this.homeData = fetchedHomeData.data.homeData;

        
    }
}
</script>