<template>
  <router-view />
</template>

<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "UPUFBIH",
  description: "Udruženje pravobranilaca/pravobranitelja u Federaciji Bosne i Hercegovine",
  ogDescription: "Udruženje pravobranilaca/pravobranitelja u Federaciji Bosne i Hercegovine",
  ogTitle: "UPUFBIH | Udruženje pravobranilaca/pravobranitelja u Federaciji Bosne i Hercegovine",
});
</script>
