<template>
    <NavbarHome3 />
    <Breadcrumb mainTitle="Galerija slika" subTitle="Galerija slika" />

    <div v-if="showSlider" class="image-gallery-modal">
    <div class="image-gallery-content">
      <ImageGallerySlider v-if="showSlider" :images="photos" :currentIndex="currentIndex" @close="closeSlider" />
      <span class="image-gallery-close" @click="closeModal">X</span>
    </div>
  </div>
  
    <div id="service-2" class="services-area section-padding pb-50">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-sm-12 mb-4"
            v-for="(photo, index) in photos"
            :key="photo.galleryID"
            @click.stop="openSlider(index)"
          >
            <img :src="photo.url" alt="upufbih" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </template>
  
  <script>
  // @ is an alias to /src
  import NavbarHome3 from "@/components/NavbarHome3.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Footer from "@/components/Footer.vue";
  import ImageGallerySlider from "@/components/ImageGallerySlider.vue";

  
  export default {
    name: "Gallery",
    components: {
      NavbarHome3,
      Breadcrumb,
      Footer,
      ImageGallerySlider,
    },
    data() {
      return {
        showSlider: false,
        currentIndex: 0,
      };
    },
  
    mounted() {
      //scrolling to top of the window
      window.scrollTo(0, 0);
    },

    computed: {
        photos() {
            return this.$store.getters.galleryPhotos;
        },
    },

    methods: {
        openSlider(index) {
            console.log('Open slider', index);
            this.currentIndex = index;
            this.showSlider = true;
        },
        closeModal() {
            this.showSlider = false;
        },
    },
  };
  </script>

<style scoped>
.image-gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.image-gallery-content {
  width: 80%;
  height: 80%;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle effect */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-gallery-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}
</style>

  