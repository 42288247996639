const getDefaultState = {
    currentEvent: {},
    events: [],
    currentProject: {},
    projects: [],
    galleryPhotos: [],
    currentDocument: {},
    documents: [],
    loading: false,
    members: [],
    organiVlasti: {},
};

export default getDefaultState;