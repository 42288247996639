export default {
    currentEvent: state => state.currentEvent,
    events: state => state.events.sort((a, b) => b.blogId - a.blogId),
    currentProject: state => state.currentProject,
    projects: state => state.projects.sort((a, b) => b.blogId - a.blogId),
    galleryPhotos: state => state.galleryPhotos.sort((a, b) => b.galleryID - a.galleryID),
    currentDocument: state => state.currentDocument,
    documents: state => state.documents.sort((a, b) => b.blogId - a.blogId),
    loading: state => state.loading,
    members: state => state.members,
    organiVlasti: state => state.organiVlasti,
};