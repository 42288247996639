import { createRouter, createWebHistory } from "vue-router";
import Home3 from "../views/Home3.vue";
import Blog from "../views/Blog.vue";
import Faq from "../views/Faq.vue";
import Service from "../views/Service.vue";
import Project from "../views/Project.vue";
import Gallery from "../views/Gallery.vue";
import BlogDetails from "../views/SingleBlog.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Registration from "../views/Registration";
import Statut from "../views/Statut";
import Kodeks from "../views/Kodeks";
import Pravila from "../views/Pravila";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home3",
    component: Home3,
    meta: {
      title: "UPUFBiH | Udruženje pravobranioca/pravobranilaca Federacije Bosne i Hercegovine",
    },
  },

  {
    path: "/novosti",
    name: "events",
    component: Blog,
  },

  {
    path: "/dokumenti",
    name: "documents",
    component: Service,
  },

  {
    path: "/projekti",
    name: "projects",
    component: Project,
  },

  {
    path: "/galerija-slika",
    name: "gallery",
    component: Gallery,
  },

  {
    path: "/o-udruzenju",
    name: "oudruzenju",
    component: Faq,
  },

  {
    path: "/obavijest/:id",
    name: "event",
    component: BlogDetails,
    beforeEnter: (to, from, next) => {
      store.dispatch("fetchEvent", to.params.id);
      next();
    }
  },

  {
    path: "/about",
    name: "About",
    component: About,
  },

  {
    path: "/kontakt",
    name: "contact",
    component: Contact,
  },

  {
    path: "/clanstvo",
    name: "clanstvo",
    component: Registration,
  },

  {
    path: "/statut",
    name: "statut",
    component: Statut,
  },

  {
    path: "/kodeks",
    name: "kodeks",
    component: Kodeks,
  },

  {
    path: "/pravila",
    name: "pravila",
    component: Pravila,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
