<template>
    <NavbarHome3 />
    <Breadcrumb mainTitle="Statut udruženja" subTitle="Statut udruženja" />
    <div style="margin: 100px;">
    <AboutTab4 />
    </div>
    <Footer />
  </template>
  
  <script>
  // @ is an alias to /src
  import NavbarHome3 from "@/components/NavbarHome3.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Footer from "@/components/Footer.vue";
  import AboutTab4 from "@/views/AboutTab4.vue";
  
  export default {
    name: "Statut",
    components: { NavbarHome3, Breadcrumb, Footer, AboutTab4 },
  
    async mounted() {
      //scrolling to top of the window
      window.scrollTo(0, 0);
    },
  };
  </script>
  <script setup>
  import { useSeoMeta } from "@vueuse/head";
  useSeoMeta({
    title: "Statut udruženja | UPUFBiH",
    description: "Statut udruženja | UPUFBiH",
    ogDescription: "Statut udruženja | UPUFBiH",
    ogTitle: "Statut udruženja | UPUFBiH",
  });
  </script>
  