export default {
    setCurrentEvent(state, event) {
        state.currentEvent = event;
    },
    setEvents(state, events) {
        state.events = events;
    },
    setCurrentProject(state, project) {
        state.currentProject = project;
    },
    setProjects(state, projects) {
        state.projects = projects;
    },
    setGalleryPhotos(state, photos) {
        state.galleryPhotos = photos;
    },
    setCurrentDocument(state, document) {
        state.currentDocument = document;
    },
    setDocuments(state, documents) {
        state.documents = documents;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setMembers(state, members) {
        state.members = members;
    },
    setOrganiVlasti(state, organiVlasti) {
        state.organiVlasti = organiVlasti;
    },
};