<template>
    <div class="w-100 position-relative" style="overflow: hidden">
      <swiper :modules="modules" :slides-per-view="1" :initial-slide="initialIndex" :space-between="0" navigation :loop="true">
        <swiper-slide v-for="(image, index) in images" :key="index">
          <div class="single-slide-item position-relative" :style="{ 'background-image': `url(${image.url})`}"></div>
        </swiper-slide>
  
        <ControlTransfer :swiperControll="swiperControll" />
      </swiper>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from "vue";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Navigation } from "swiper";
  
  const props = defineProps(["images", "currentIndex"]);
  
  const modules = [Navigation];
  const initialIndex = props.currentIndex;
  
  </script>
  
  <style>
  .swiper-button-next,
  .swiper-button-prev {
    width: 68px;
    height: 58px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .swiper-button-prev {
  background-image: url('/public/assets/images/left_blob.png'); /* Replace with the correct path to your previous icon */
}

.swiper-button-next {
  background-image: url('/public/assets/images/right_blob.png'); /* Replace with the correct path to your next icon */
}
  </style>
  