<template>
    <div class="about-tab">
  <p style="text-align: left;">Na osnovu člana 18. Zakona o udruženjima i fondacijama ( “Službene novine Federacije Bosne i Hercegovine” broj 45/02), Skupština udruženja pravobranilaca/ pravobranitelja u Federaciji Bosne i Hercegovine, na redovnoj sjednici održanoj dana 30.09.2010. godine donosi:</p>
  <p style="text-align: center; font-size: 25px;"><span style="color: #bea77c;"><strong>STATUT<br>UDRUŽENJA PRAVOBRANILACA / PRAVOBRANITELJA<br>U FEDERACIJI BOSNE I HECEGOVINE</strong></span></p>
  <p style="text-align: center;"><strong>I. OSNOVNE ODREDBE</strong><br><strong>Član 1.</strong></p>
  <p>Udruženje pravobranilaca / pravobranitelja ( u daljem tekstu udruženje ) predstavlja asocijaciju koja je formirana na slobodnoj osnovi. Svi pravobranioci / pravobranitelji njhovi zamjenici i pomoćnici u Federaciji Bosne i Hercegovine imaju pravo na slobodno udruživanje kroz Udruženje, s ciljem efikasnijeg ostvarivanja profesionalnih aktivnosti usmjerenih na jačanje digniteta pravobranilačke funkcije, te promovisanja i zaštite kako zajedničkih tako i pojedinačnih prava i interesa svakog člana udruženja</p>
  <p style="text-align: center;"><strong>Član 2.</strong></p>
  <p>Puni naziv Udruženja glasi: UDRUŽENJE PRAVOBRANILACA/PRAVOBRANITELJA U FEDERACIJI BOSNE I HERCEGOVINE. Skraćeni naziv udruženja je:„UPUFBIH“ Sjedište Udruženja je u Sarajevu. Udruženje djeluje na područiju Federacije Bosne i Hercegovine</p>
  <p style="text-align: center;"><strong>Član 3.</strong></p>
  <p>Udruženje ima pečat i štambilj. Pečat je okruglog oblika, prečnika 40 mm i sadrži naziv i sjedište udruženja na jezicima konstitutivnihnaroda, ispisan latiničnim i čiriličnim pismom u koncentričnim krugovima, sa grafičkim znakom udruženja u sredini.Štambilj je pravougaonog oblika dimenzija 40 X 30 mm i sadrži sjedište i naziv udruženja, broj akta i datum.</p>
  <p style="text-align: center;"><strong>Član 4.</strong></p>
  <p>Udruženje ima grafički znak u obliku vage koja se sastoji od vertikalnog postolja, te simetrično postavljene dvije posude.</p>
  <p style="text-align: center;"><strong>Član 5.</strong></p>
  <p>Način upotrebe pečata i znaka regulisat će se posebnim uputstvom koje donosi Skupština Udruženja. Do donošenja uputstva pravo raspolaganja pečatom i štambiljom ima predsjednik Udruženja.</p>
  <p style="text-align: center;"><strong>II PROGRAMSKI CILJEVI I DJELATNOST UDRUŽENJA</strong><br><strong>Član 6.</strong></p>
  <p>Programski ciljevi udruženja su:</p>
  <ul class="check-list pf-list">
    <li>Zalaganje za dosljednu primjenu ustava i zakona te jačanje pravne države;</li>
    <li>Afirmacija ljudskih prava u skladu sa najvišim međunarodnim standardima;</li>
    <li>Zalaganje za efikasno funkcionisanje pravobranilačke funkcije ,</li>
    <li>Davanje prijedloga i sugestija nadležnim organima u pitanjima bitnim za funkcionisanje pravobranilaštava;</li>
    <li>Davanje mišljenja o nacrtima zakona na traženje nadležnih organa ili na vlastitu inicijativu;</li>
    <li>Stručno usavršavanje pravobranilaca;</li>
    <li>Saradnja sa drugim udruženjima pravobranilaca</li>
    <li>Kontinuirano unapređenje uloge pravobranilaštva u cilju efikasnije zaštite povjerene imovine i imovinskih interesa.</li>
    <li>Jačanje ugleda te pravnog i materijalnog položaja pravobranilaca</li>
    <li>Kontinuirano razvijanje odnosa kolegijalnosti između članova udruženja</li>
    <li>Poduzimanje mjera i radnji u cilju zaštite prava i interesa pravobranilaca članova udruženja u slučaju pozivanja na odgovornost u vezi sa obavljanjem pravobranilačke funkcije</li>
    <li>Zaštita interesa svakog pravobranioca člana udruženja u slučaju pozivanja na odgovornost za dato stručno pravno mišljenje.</li>
  </ul>
  <p style="text-align: center;"><strong>Član 7.</strong></p>
  <p>Radi ostvarivanja programskih ciljeva iz člana 6. Statuta, udruženje će:</p>
  <ul class="check-list pf-list">
    <li>Organizovati po potrebi predavanja, seminare, savjetovanja i studijska putovanja, te će učestvovati na savjetovanjima i seminarima drugih udruženja i institucija</li>
    <li>Inicirati izmjene zakona i drugih propisa radi unapređenja pravobranilačke funkcije te radi zaštite imovine i imovinskih interesa Federacije, Kantona, Grada i Općine.</li>
    <li>Zauzimati pravna stajališta o pitanjima od interesa za funkcionisanje pravobranilačke funkcije i pravnog sistema uopće;</li>
    <li>Izdavati časopise i stručne publikacije;</li>
    <li>Pružati pomoć članovima Udruženja u objavljivanju stručnih radova i prilikom stručnog usavršavanja.</li>
  </ul>
  <p style="text-align: center;"><strong>III ČLANSTVO U UDRUŽENJU</strong><br><strong>Član 8.</strong></p>
  <p style="text-align: center;"><strong>Prava i dužnosti članova udruženja</strong></p>
  <p>Članovi Udruženja mogu biti pravobranioci, zamjenici pravobranilaca, pomoćnici pravobranilaca, pravobranioci u penziji ( u daljem tekstu pravobranioci ). Pravobranioci postaju članovi Udruženja potpisivanjem pristupnice, te donošenjem odluke o pristupanju pravobranioca u udruženje pravobranilaca. Odluku o pristupanju pravobranioca u udruženje pravobranilaca donosi skupština udruženja.</p>
  <p style="text-align: center;"><strong>Član 9.</strong></p>
  <p>Udruženje može birati počasnog člana Udruženja, o čemu odluku donosi Skupština udruženja.</p>
  <p style="text-align: center;"><strong>Član 10.</strong></p>
  <p>Član Udruženja ima člansku kartu čiji izgled i sadržinu propisuje Skupština udruženja.</p>
  <p style="text-align: center;"><strong>Član 11.</strong></p>
  <p>Prava člana udruženja su:</p>
  <ul class="check-list pf-list">
    <li>Da sudjeluje u radu Udruženja;</li>
    <li>Da bira i da bude biran u organe Udruženja;</li>
    <li>Da bude upoznat sa radom svih organa Udruženja;</li>
    <li>Da daje primjedbe na rad organa Udruženja te da daje prijedloge i sugestije;</li>
    <li>Da sudjeluje na predavanjima, seminarima, savjetovanjima i drugim stručnim skupovima</li>
    <li>Da uživa podršku i zaštitu Udruženja u slučajevima predviđenim ovim Statutom.</li>
  </ul>
  <p style="text-align: center;"><strong>Član 12.</strong></p>
  <p>Dužnosti člana udruženja su:</p>
  <ul class="check-list pf-list">
    <li>Da poštuje i sprovodi odluke i zaključke organa Udruženja;</li>
    <li>Da sarađuje sa drugim članovima Udruženja radi ostvarivanja programskih ciljeva i zadataka Udruženja;</li>
    <li>Da redovno plaća članarinu;</li>
    <li>Da gradi ugled Udruženja;</li>
    <li>Da se zalaže za očuvanje digniteta pravobranilačke funkcije;</li>
    <li>Da se pridržava kodeksa pravobranilačke etike.</li>
  </ul>
  <p style="text-align: center;"><strong>Član 13.</strong></p>
  <p>Članstvo u udruženju prestaje:</p>
  <ul class="check-list pf-list">
    <li>Istupanjem;</li>
    <li>Smrću;</li>
    <li>Isključenjem zbog povreda kodeksa pravobranilačke etike;</li>
    <li>Prestankom funkcije za koju je vezano članstvo, osim penzionisanjem;</li>
    <li>Neplaćanjem članarine tri mjeseca uzastopno ili više mjeseci u toku godine</li>
  </ul>
  <p style="text-align: center;"><strong>Član 14.</strong></p>
  <p>U slučaju istupanja iz udruženja, svaki član udruženja dužan je dostaviti pismenu izjavu kojom izražava svoju namjeru istupanja iz udruženja.. Na osnovu takve izjave, Skupština udruženja na prvoj narednoj sjednici, donosi odluku o prestanku članstva navedene osobe iz udruženja.</p>
  <p style="text-align: center;"><strong>Član 15.</strong></p>
  <p>U slučaju isključenja člana iz udruženja<strong>&nbsp;</strong>zbog utvrđenih povreda kodeksa pravobranilačke etike, odluku o prestanku članstva u udruženju donosi predsjednik udruženja na osnovu prethodno provedenog postupka i izrečene mjere od strane Suda časti o postojanju povreda pravobranilačke etike, odnosno konačne odluke skupštine udruženja kojom se potvrđuje izrečena mjera.</p>
  <p style="text-align: center;"><strong>Član 16.</strong></p>
  <p>Informaciju i saznanja o ponašanju člana udruženja kojim se narušavaju pravila kodeksa pravobranilačke etike može dostaviti svako ko raspolaže takvim saznanjima. Informacija se podnosi u pismenoj formi Predsjedniku udruženja.</p>
  <p style="text-align: center;"><strong>Član 17.</strong></p>
  <p>Predsjednik udruženja se obavezuje pokrenuti postupak pred sudom časti, ukoliko na bilo koji način raspolaže informacijama o mogućim kršenjima kodeksa pravobranilačke etike te kršenjima ugleda pravobranilačke funkcije.</p>
  <p style="text-align: center;"><strong>Član 18.</strong></p>
  <p>Članu udruženja prestankom funkcije pravobranioca, prestaje i članstvo u udruženju pravobranilaca. U tom slučaju član udruženja, dužan je pismeno obavijestiti udruženje pravobranilaca o prestanku funkcije pravobranioca.</p>
  <p style="text-align: center;"><strong>Član 19.</strong></p>
  <p>Ukoliko član udruženja uzastopno tri mjeseca ili više mjeseci u toku godine, bez opravdanog razloga neplaća članarinu, istom će u skladu sa članom 13. ovog statuta prestati članstvo u udruženju. Postupak prestanka članstva u udruženju pokreće predsjednik udruženja.</p>
  <p style="text-align: center;"><strong>IV ORGANI UDRUŽENJA</strong><br><strong>Član 20.</strong></p>
  <p>Organi Udruženja su:</p>
  <ul class="check-list pf-list">
    <li>Skupština Udruženja</li>
    <li>Predsjednik udruženja</li>
    <li>Predsjedništvo Udruženja</li>
    <li>Sekretar</li>
    <li>Sud časti</li>
    <li>Nadzorni odbor</li>
  </ul>
  <p style="text-align: center;"><strong>Član 21.</strong></p>
  <p style="text-align: center;"><strong>Skupština udruženja</strong></p>
  <p>Skupština udruženja je najviši organ odlučivanja u Udruženju. Skupštinu Udruženja čine svi članovi udruženja. Skupštinu zakazuje i saziva predsjednik udruženja. Inicijativu za održavanje skupštine može pokrenuti najmanje pet članova udruženja.</p>
  <p style="text-align: center;"><strong>Član 22.</strong></p>
  <p>Skupština udruženja može biti redovna i vanredna skupština. Redovna skupština se održava najmanje jednom godišnje. Vanredna Skupština se zakazuje i održava po potrebi. Vanrednu skupštinu mogu zakazati, predsjedništvo na vlastitu inicijativu, na zahtjev nadzornog odbora, ili na zahtjev najmanje trećine članova udruženja. Redovna ili vanredna Skupština Udruženja mora se objaviti najmanje osam dana prije održavanja sa naznakom vremena i mjesta održavanja i sa prethodno utvrđenim dnevnim redom.</p>
  <p style="text-align: center;"><strong>Član 23.</strong></p>
  <p>Skupština može zasjedati ako je prisutna većina članova udruženja, a odluke donosi ukoliko za njih glasa većina prisutnih članova skupštine. Skupština donosi odluke javnim glasanjem, na sjednici Skupštine se može odlučiti da se o pojedinim pitanjima odluke donose tajnim glasanjem.</p>
  <p style="text-align: center;"><strong>Član 24.</strong></p>
  <p>Radom skupštine rukovodi i predsjedava predsjednik udruženja. Predsjenik udruženja se brine i stara o sprovođenju i izvršavanju odluka skupštine udruženja. Kada je donošenje i sprovođenje pojedinih odluka nespojivo sa funkcijom koju obavlja predsjednik udruženja, donošenje i sprovođenje takvih odluka će preuzeti zamjenik predsjednika udruženja.</p>
  <p style="text-align: center;"><strong>Član 25.</strong></p>
  <p>Skupština Udruženja u okviru svojih nadležnosti:</p>
  <ul class="check-list pf-list">
    <li>Donosi statut, izmjene i dopune statuta ;</li>
    <li>Donosi poslovnik o radu;</li>
    <li>Donosi kodeks pravobranilačke etike</li>
    <li>Donosi pravila u postupku pred sudom časti</li>
    <li>Bira i razrješava predsjednika i članove predsjedništva</li>
    <li>Bira i razrješava članove nadzornog odbora</li>
    <li>Bira i razrješava članove suda časti</li>
    <li>Bira i razrješava sekretara udruženja</li>
    <li>Donosi finansijski plan i usvaja završni račun, te izvještaj o radu predsjedništva.</li>
    <li>Odlučuje po žalbama izjavljenim protiv odluka suda časti.</li>
    <li>Odlučuje o prijemu članova Udruženja.</li>
    <li>Daje saglasnost na pravne radnje poduzete u postupku osnivanja udruženja:</li>
    <li>Odlučuje o udruživanju u saveze, pripajanju, razdvajanju, transformaciji, prestanku rada i drugim statusnim promjenama Udruženja;</li>
    <li>Odlučuje o svim pitanjima koja nisu u nadležnosti drugih organa.</li>
  </ul>
  <p style="text-align: center;"><strong>Član 26.</strong></p>
  <p>Predsjedništvo udruženja</p>
  <p>Predsjedništvo je organ upravljanja Udruženjem. Predsjedništvo se sastoji od 5 članova koje bira Skupština. Mandat članova predsjedništva traje dvije godine.</p>
  <p style="text-align: center;"><strong>Član 27.</strong></p>
  <p>Predsjedništvo može zasjedati ako je na sjednici prisutna većina članova, a odluke donosi većinom glasova prisutnih članova.</p>
  <p style="text-align: center;"><strong>Član 28.</strong></p>
  <p>Predsjedništvo izvršava odluke Skupštine i rukovodi radom Udruženja između dvije Skupštine. Predsjedništvo naročito obavlja sljedeće poslove:</p>
  <ul class="check-list pf-list">
    <li>Utvrđuje prijedlog Statuta, izmjene i dopune Statuta;</li>
    <li>Donosi poslovnik o svom radu;</li>
    <li>Utvrđuje prijedlog programa rada Udruženja;</li>
    <li>Utvrđuje prijedlog finansijskog plana i završnog računa, te raspolaže sredstvima u okviru finansijskog plana;</li>
    <li>Utvrđuje prijedlog pravila pred Sudom časti;</li>
    <li>Utvrđuje prijedlog Kodeksa pravobranilačke etike;</li>
    <li>Organizuje seminare, savjetovanja i studijska putovanja;</li>
    <li>Formira stručne grupe;</li>
    <li>Kordinira rad drugim organima</li>
    <li>Koordinira aktivnosti u vezi sa izdavačkom djelatnošću Udruženja i ocjenjuje rad predsjednika, zamjenika,</li>
    <li>Donosi odluku o dodjeli zahvalnica Udruženja;</li>
    <li>Na prijedlog Komisije za stručno usavršavanje donosi odluku o raspodjeli sredstava za usavršavanje;</li>
    <li>Donosi odluku o visini jednokratne novčane pomoći članu Udruženja u slučaju teške bolesti ili teške invalidnost, te u slučaju stanja nužne finansijske potrebe.</li>
  </ul>
  <p style="text-align: center;"><strong>Član 29.</strong></p>
  <p>Predsjednik udruženja</p>
  <p>Skupština Udruženja bira predsjednika i zamjenika predsjednika Udruženja većinom glasova prisutnih članova udruženja. Predsjednik i zamjenik predsjednika, zastupaju i predstavljaju udruženje</p>
  <p style="text-align: center;"><strong>Član 30.</strong></p>
  <p>U slučaju spriječenosti ili odsutnosti predsjednika, istog zamjenjuje zamjenik predsjednika koji u odsutnosti predsjednika ima iste ovlasti kao i predsjednik.</p>
  <p style="text-align: center;"><strong>Član 31.</strong></p>
  <p>Sekretar udruženja</p>
  <p>Sekretar udruženja je ujedno i sekretar predsjedništva te sekretar skupštine udruženja. Sekretar priprema sjednice predsjedništva te sjednice skupštine, priprema izvještaj o radu udruženja, te obavlja administrativno tehničke poslove, kao i druge poslove koji mu budu povjereni. U slučaju odsutnosti sekretara, istog zamjenjuje član udruženja kojeg odredi predsjednik skupštine.</p>
  <p style="text-align: center;"><strong>Član 32.</strong></p>
  <p style="text-align: center;"><strong>Sud časti</strong></p>
  <p>Udruženje ima Sud časti koji se sastoji od pet članova. Članove Suda časti bira Skupština udruženja na period od dvije godine.</p>
  <p style="text-align: center;"><strong>Član 33.</strong></p>
  <p>Sud časti na osnovu provedenog postupka, koji se vodi u skladu sa pravilima u postupku pred sudom časti, utvrđuje osnovanost postojanja povreda Kodeksa pravobranilačke etike, te u tom slučaju izriče mjeru koja može biti, isključenje člana iz Udruženja ili izricanje opomene.</p>
  <p style="text-align: center;"><strong>Član 34.</strong></p>
  <p>Sud časti donosi odluke iz predhodnog člana, većinom glasova prisutnih članova.</p>
  <p style="text-align: center;"><strong>Član 35.</strong></p>
  <p>Protiv odluke suda časti dozvoljena je žalba u roku od 15 dana Skupštini Udruženja, čija je odluka konačna.</p>
  <p style="text-align: center;"><strong>Član 36.</strong></p>
  <p>Konačna odluka o isključenju člana udruženja, dostavlja se organu koji je nadležan za imenovanje pravobranioca,</p>
  <p style="text-align: center;"><strong>Član 37.</strong></p>
  <p style="text-align: center;"><strong>Nadzorni odbor</strong></p>
  <p>Nadzorni odbor nadzire rad Predsjedništva i drugih organa Udruženja, te najmanje jednom godišnje kontroliše materijalno i finansijsko poslovanje Udruženja. Skupština bira tri člana Nadzornog odbora iz redova članova Udruženja na period od dvije godine, a članovi Nadzornog odbora iz svojih redova biraju predsjednika.</p>
  <p style="text-align: center;"><strong>Član 38.</strong></p>
  <p>O svom radu i o uočenim nedostacima u radu drugih organa Nadzorni odbor podnosi</p>
  <p>izvještaj Skupštini Udruženja. Nadzorni odbor radi u sjednicama i donosi odluke većinom glasova.</p>
  <p style="text-align: center;"><strong>Član 39.</strong></p>
  <p>Stručne i administrativne poslove za potrebe Udruženja obavlja sekretar udruženja, dok knjigovodstvene poslove, obavlja ovlašteni knjigovodstveni servis.</p>
  <p style="text-align: center;"><strong>Član 40</strong>.</p>
  <p>Rad Udruženja je javan. Javnost rada se ostvaruje putem sredstava javnog informisanja.</p>
  <p style="text-align: center;"><strong>VI. IMOVINA UDRUŽENJA</strong><br><strong>Član 41.</strong></p>
  <p>Udruženje stiče imovinu od:</p>
  <ul class="check-list pf-list">
    <li>Članarine;</li>
    <li>Dobrovoljnih priloga i poklona fizičkih i pravnih lica, ukoliko isti ne ugrožavaju i ne utječu na nezavisnost udruženja i njegovih članova.</li>
    <li>Sredstava dodjeljenih iz budžeta;</li>
    <li>Drugih prihoda stečenih u skladu sa Zakonom i Statutom.</li>
  </ul>
  <p>Cjelokupna finansijsko materijalna sredstva Udruženja se vode u skladu sa zakonskim propisima. Predsjedništvo vodi evidenciju o pokretnoj i nepokretnoj imovini Udruženja i vrijednost imovine unosi u godišnji bilans.</p>
  <p style="text-align: center;"><strong>Član 42.</strong></p>
  <p>Udruženje ima svoj žiro račun.</p>
  <p style="text-align: center;"><strong>Član 43.</strong></p>
  <p>Platne transakcije obavlja i&nbsp;<strong>odobrava</strong><strong>&nbsp;</strong>predsjednik Udruženja, ili lice koje on ovlasti. Predsjednik udruženja je jedino lice koje je ovlašteno za raspolaganje novčanim sredstvima udruženja..</p>
  <p style="text-align: center;"><strong>VI JAVNA PRIZNANJA UDRUŽENJA</strong><br><strong>Član 44.</strong></p>
  <p>Javna priznanja koja Udruženje dodijeljuje su:</p>
  <p>– “Nagrada Udruženja za izuzetan doprinos u unapređenju pravobranilačke funkcije ” – “Zahvalnica udruženja praobranilaca ”.</p>
  <p style="text-align: center;"><strong>Član 45.</strong></p>
  <p>Nagrada Udruženja za izuzetan doprinos u unapređenju pravobranilačke funkcije. Predlagač ovog javnog priznanja može biti svaki član Udruženja. Obrazloženi prijedlozi za nagradu se dostavljaju Predsjedništvu Udruženja. Prijedlog treba da sadrži razloge zbog kojih predlagač smatra da predloženi kandidat zaslužuje dodjelu nagrade. Odluku o dodjeli nagrade donosi Skupština Udruženja (tajnim) izjašnjavanjem članova Skupštine. Nagrada se dodjeljuje onom kandidatu koji dobije najveći broj glasova. Nagrada se dodjeljuje pravobraniocima i pravobraniocima u penziji / mirovini, a može se dodijeliti i posthumno. Predsjedništvo Udruženja će svojom odlukom propisati dizajn (izgled) nagrade koja će biti u formi medaljona i značke. Dobitniku nagrade će se uručiti i pismena potvrda o dodjeli.</p>
  <p style="text-align: center;"><strong>Član 46.</strong></p>
  <p>Zahvalnica Udruženja dodjeljuje se pojedincima ili udruženjima koji su svojim aktivnostima unaprijedili ili pomogli rad Udruženja. Odluku o dodjeli Zahvalnice donosi Predsjedništvo Udruženja na prijedlog članova udruženja. Predsjedništvo će svojom odlukom propisati formu Zahvalnice.</p>
  <p style="text-align: center;"><strong>VII. POMOĆ PRI USAVRŠAVANJU</strong><br><strong>Član 47.</strong></p>
  <p>Udruženje iz svojih sredstava u skladu sa raspoloživim mogućnostima, za stručno usavršavanje članova Udruženja može obezbijediti iznos do10% ukupnog prihoda Udruženja ili do iznosa predviđenog godišnjim planom. Odluku o raspodijeli sredstava za stručno usavršavanje donosi Predsjedništvo na prijedlog Komisije za stručno usavršavanje, a u skladu sa Pravilnikom o uslovima i kriterijima za dodjelu sredstava za usavršavanje, odnosno Pravilnikom za stručno usavršavanje.</p>
  <p style="text-align: center;"><strong>VIII. NAKNADA ZA SLUČAJ TEŠKE INVALIDNOSTI ILI TEŠKE BOLESTI</strong><br><strong>Član 48.</strong></p>
  <p>U slučaju teške bolesti ili teške invalidnosti člana Udruženja, Udruženje jednom godišnje isplaćuje jednokratnu novčanu pomoć u visini koju će na sjednici odlukom odrediti skupština Udruženja. Članu Udruženja može se u skladu sa raspoloživim sredstvima isplatiti dio ili ukupni troškovi liječenja teške invalidnosti ili teške bolesti u zdravstvenoj ustanovi u kojoj je platio troškove tog liječenja. Troškovi liječenja se isplačuju na osnovu računa zdravstvene ustanove u kojoj je liječenje obavljeno. Bolest, odnosno teška povreda se dokazuje na osnovu dijagnoze ovlaštenog lica zdravstvene struke, odnosno zdravstvene ustanove.</p>
  <p style="text-align: center;"><strong>IX PRESTANAK RADA UDRUŽENJA</strong><br><strong>Član 49.</strong></p>
  <p>Udruženje prestaje sa radom:</p>
  <ul class="check-list pf-list">
    <li>Odlukom Skupštine Udruženja;</li>
    <li>Ako rad Udruženja zabrani nadležni organ.</li>
  </ul>
  <p>Udruženje se može udruživati u saveze ili pripojiti, razdvojiti ili transformisati samo u drugo udruženje.</p>
  <p style="text-align: center;"><strong>Član 50.</strong></p>
  <p>U slučaju prestanka rada Udruženja, Skupština Udruženja će donijeti odluku da se imovina Udruženja, nakon podmirivanja obaveza predaje Ministarstvu pravde Federacije Bosne i Hercegovine da osnuje udruženje sa istim ili sličnim programskim ciljevima.</p>
  <p>Ako se osnuje novo udruženje sa istim ili sličnim programskim ciljevima, imovina se predaje novom udruženju.</p>
  <p style="text-align: center;"><strong>Član 51.</strong></p>
  <p>Kada Udruženje prestane sa radom, predsjednik Udruženja će podnijeti zahtijev nadležnom organu za brisanje Udruženja iz registra.</p>
  <p style="text-align: center;"><strong>X PRELAZNE I ZAVRŠNE ODREDBE</strong><br><strong>Član 52.</strong></p>
  <p>Nacrt Statuta i izmjene i dopune Statuta priprema i utvrđuje Predsjedništvo i upućuje na javnu raspravu članovima Udruženja. Predsjedništvo razmatra primjedbe i prijedloge koji su dati u raspravi, zauzima stavove o njima, utvrđuje prijedlog Statuta, izmjene i dopune Statuta i upućuje ih Skupštini na usvajanje. Ostali opći akti Udruženja donose se na način i po postupku za njihovo donošenje.</p>
  <p style="text-align: center;"><strong>Član 53.</strong></p>
  <p>Ovaj Statut stupa na snagu danom donošenja. Danom stupanja na snagu ovog Statuta, prestaje da važi Statut donesen 23.06.2000, godine.</p>
</div>
</template>
<script setup></script>