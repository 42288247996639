import axios from 'axios';

const API_URL = 'https://api.upufbih.ba/api/';
// const API_URL = 'http://localhost:5000/api/';

export default {
    setLoading({ commit }, loading) {
        commit('setLoading', loading);
    },

    async fetchEvents({ commit }, tag) {
        const response = await axios.get(`${API_URL}Blogs/GetActiveBlogs?tag=${tag}`);
        commit('setEvents', response.data);
    },
    async fetchProjects({ commit }, tag) {
        const response = await axios.get(`${API_URL}Blogs/GetActiveBlogs?tag=${tag}`);
        commit('setProjects', response.data);
    },
    async fetchGalleryPhotos({ commit }) {
        const response = await axios.get(`${API_URL}Gallery/GetActivePhotos`);
        commit('setGalleryPhotos', response.data);
    },
    async fetchDocuments({ commit }, tag) {
        const response = await axios.get(`${API_URL}Blogs/GetActiveBlogs?tag=${tag}`);
        commit('setDocuments', response.data);
    },

    async fetchEvent({ commit }, id) {
        const response = await axios.get(`${API_URL}Blogs/GetBlogById?id=${id}`);
        commit('setCurrentEvent', response.data);
    },

    async addMember({ commit }, payload) {
        const response = await axios.post(`${API_URL}Users/AddUser`, payload);
        commit('addMember', response.data);    
    },

    async fetchMembers({ commit }) {
        const response = await axios.get(`${API_URL}Users/GetActiveUsers?UserId=1`, {
            pageNumber: 1,
            pageSize: 50,
            userId: 1,
        });
        commit('setMembers', response.data);
    },

    async fetchOrganiVlasti({ commit }) {
        // Organi vlasti id is 21 in the database or tag name is "organi"
        const organiVlastiId = 21;
        const response = await axios.get(`${API_URL}Blogs/GetBlogById?id=${organiVlastiId}`);
        commit('setOrganiVlasti', response.data);
    },
};