<template>
  <NavbarHome3 />
  <Breadcrumb mainTitle="Dokumenti" subTitle="Dokumenti" />
  <!-- Service Area -->

  <div id="service-3" class="serv-area sky-bg section-padding">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft"
          data-wow-delay=".2s"
          v-for="service in documents"
          :key="service.blogId"
        >
          <div class="single-serv-wrapper">
            <div class="row gx-4">
              <div class="col-lg-12">
                <div class="single-serv-area">
                  <div class="service-icon">
                    <i class="flaticon-certificate"></i>
                  </div>
                  <h5>{{ service.title }}</h5>
                  <p></p>
                  <a :href="service.fileUrl" target="_blank" class="main-btn small-btn">Preuzmi dokument</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "Service",
  components: {
    NavbarHome3,
    Breadcrumb,
    Footer,
  },
  data() {
    return {
    };
  },

  mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },

  computed: {
    documents() {
      return this.$store.getters.documents;
    },
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";

useSeoMeta({
  title: "Dokumenti | UPUFBiH",
  description: "Dokumenti | UPUFBiH",
  ogDescription: "Dokumenti | UPUFBiH",
  ogTitle: "Dokumenti | UPUFBiH",
});
</script>
