import { createApp } from "vue";
import store from './store';
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router";

const head = createHead();

const app = createApp(App);
app.use(router);
app.use(store);
app.use(head);
app.mount("#app");
