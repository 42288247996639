<template>
  <!-- Header Area -->

  <header id="header-3" class="header-area">
    <div class="sticky-area">
      <div class="navigation">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="logo">
                <router-link :to="{ name: 'Home3' }" class="navbar-brand"
                  ><img src="/assets/img/logo-white.png" alt=""
                /></router-link>
              </div>
            </div>

            <div class="col-lg-6 col-12">
              <div class="main-menu">
                <nav class="navbar navbar-expand-lg">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    class="collapse navbar-collapse justify-content-center"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav m-auto">
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Home3' }"
                          >Početna</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'oudruzenju' }"
                          >O udruženju</router-link
                        >
                      </li>
                      <!-- <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'events' }"
                          >Događaji</router-link
                        >
                      </li> -->
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'documents' }"
                          >Dokumenti</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'projects' }"
                          >Projekti</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'gallery' }"
                          >Galerija</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div class="col-lg-3 col-12 text-end">
              <div class="header-right-content">
                <router-link class="main-btn" :to="{ name: 'clanstvo' }"
                  >Postani član udruženja</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  async beforeMount() {
  },

  mounted() {

    this.$store.dispatch("fetchEvents", "oglasna");
    this.$store.dispatch("fetchDocuments", "dokumenti");
    this.$store.dispatch("fetchProjects", "radovi-projekti");
    this.$store.dispatch("fetchGalleryPhotos");
    this.$store.dispatch("fetchMembers");
    this.$store.dispatch("fetchOrganiVlasti");
    //jQuery Sticky Area
    $(".sticky-area").sticky({
      topSpacing: 0,
    });

    // Mobile Menu
    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("active");
    });

    $(".navbar-nav li a").on("click", function () {
      $(".sub-nav-toggler").removeClass("active");
    });

    var subMenu = $(".navbar-nav  .sub-menu");
    if (subMenu.length) {
      subMenu
        .parent("li")
        .children("a")
        .append(function () {
          return '<button class="sub-nav-toggler"> <i class="las la-angle-down"></i> </button>';
        });

      var subMenuToggler = $(".navbar-nav .sub-nav-toggler");

      subMenuToggler.on("click", function () {
        $(this).parent().parent().children(".sub-menu").slideToggle();
        return false;
      });
    }

    // Menu Active Color

    $(".main-menu .navbar-nav .nav-link").on("mouseover", function () {
      $(".main-menu .navbar-nav .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  },
  setup() {},
};
</script>
