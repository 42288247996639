<template>
<div class="about-tab">
   <div v-html="organiVlasti" />
<!--    <p><strong>PREDSJEDNIK UDRUŽENJA</strong></p>
   <ul class="check-list pf-list">
      <li>Edita Ljubičić</li>
   </ul>
   <p><strong>ZAMJENIK PREDSJEDNIKA UDRUŽENJA</strong></p>
   <ul class="check-list pf-list">
      <li>Imšir Škahić</li>
   </ul>
   <p><strong>PREDSJEDAVAJUĆI SKUPŠTINE UDRUŽENJA</strong></p>
   <ul>
      <li>Sedžad Kliko</li>
   </ul>
   <p><strong>ZAMJENIK PREDSJEDAVAJUĆEG SKUPŠTINE UDRUŽENJA</strong></p>
   <ul>
      <li>Vesna Petrić</li>
   </ul>
   <p><strong>PREDSJEDNIŠTVO</strong></p>
   <ul class="check-list pf-list">
      <li>Sanja Pajić – Mostar</li>
      <li>Vesna Glavaš – Mostar</li>
      <li>Marica Ljubić – Mostar</li>
      <li>Nermina Arapović – Sarajevo</li>
      <li>Adnan Kečo – Sarajevo</li>
   </ul>
   <p><strong>NADZORNI ODBOR</strong></p>
   <ul class="check-list pf-list">
      <li>Fatima Alihodzić</li>
      <li>Jasmina Karović</li>
      <li>Anita Perić</li>
   </ul>
   <p><strong>SUD ČASTI</strong></p>
   <ul class="check-list pf-list">
      <li>Dejana Šimić</li>
      <li>Amira Salihagić – Sedlarević</li>
      <li>Sadžida Terzić</li>
      <li>Alija Halilović</li>
      <li>Vlado Bevanda</li>
   </ul>
   <p><strong>SEKRETAR UDRUŽENJA<br></strong></p>
   <ul>
      <li>Jasmina Košpo</li>
   </ul> -->
</div>
    
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const organiVlasti = computed(() => {
    return store.getters.organiVlasti?.content || '';
});


</script>
<style scoped>
p {
    margin: 0 !important;
    padding: 0 !important;
}
p strong {
    margin-bottom: 10px !important;
}
</style>
