<template>
    <div class="about-tab">
        <p>Na osnovu člana 18. Statuta udruženja pravobranilaca / pravobranitelja u Federaciji Bosne i Hercegovine, Skupština udruženja pravobranilaca na prijedlog predsjedništva udruženja pravobranilaca Federacije Bosne i Hercegovine na sjednici koja je održana dana 30.09.2010. godine donosi:</p>
        <p style="text-align: center; font-size: 25px;"><span style="color: #bea77c;"><strong> KODEKS PRAVOBRANILAČKE ETIKE</strong></span></p>
        <p style="text-align: center;"><strong>Član1.</strong></p>
        <p>Uvažavajući ulogu i značaj pravobranilačke funkcije u pogledu zaštite imovine i imovinskih imteresa Federacije Bosne i Hercegovine, Kantona, te Gradova i Općina, u cilju primata vladavine zakona, te u cilju zaštite digniteta institucije pravobrabranioca, te uvažavanja i zaštite moralnih vrijednosti, utvrđuju se pravila ponašanja i postupanja pravobranioca prilikom obavljanja pravobranilačke funkcije.</p>
        <p style="text-align: center;"><strong>Član 2.</strong></p>
        <p>Pravobranioci uživaju punu samostalnost i neovisnost prilikom obavljanja poslova i radnih zadataka iz svoje nadležnosti.</p>
        <p style="text-align: center;"><strong>Član 3.</strong></p>
        <p>Pravobranioci će svoje zakonom propisane nadležnosti primjenjivati nezavisno od volje pojedinaca iz izvršne ili zakonodavne vlasti. Isti neće biti pod utjecajem niti djelovati po smjernicama drugih ogranaka vlasti.</p>
        <p style="text-align: center;"><strong>Član 4.</strong></p>
        <p>Pravobranioci će poslove iz svoje nadležnosti obavljati stručno, profesionalno, i kvalitetno, sve u cilju zaštite imovine i imovinskih interesa Federacije, Kantona Gradova i Općina. Pravobranioci će u obavljanju poslova iz stava 1. ovog člana poštivati Ustav Bosne i Hercegovine i Ustav Federacije Bosne i Hercegovine. U obavljanju poslova iz svoje nadležnosti pravobranioci moraju biti imuni na lokalne, entitetske ili nacionalne političke uticaje bilo kakve vrste.</p>
        <p style="text-align: center;"><strong>Član 5.</strong></p>
        <p>Vođeni najvišim moralnim vrijednostima, u granicama zakona te u skladu sa ustavnim načelima, pravobranioci će se boriti za vladavinu prava te zaštitu imovinskih i drugih interesa pravnih subjekata koje u postupcima zastupaju. Prilikom zastupanja pravobranilac se nikada ne smije staviti u zavistan položaj u odnosu na suprotnu stranu ili u odnosu na lični interes.</p>
        <p style="text-align: center;"><strong>Član 6.</strong></p>
        <p>Odnos pravobranioca prema pravnom subjektu kojeg zastupa, protivnoj stranci i njenim zastupnicima, sudovima, te drugim državnim organima mora biti primjeren i profesionalan bez bilo kakvog vrijeđanja ili omalovažavanja bilo kojeg subjekta.</p>
        <p style="text-align: center;"><strong>Član 7.</strong></p>
        <p>Pravobranilac je dužan savjesno i stručno ispunjavati sve povjerene dužnosti koje su u njegovoj nadležnosti, te pri tome čuvati ugled i dostojanstvo, pravobranilačke funkcije kako pri obavljanju navedenih poslova tako i u privatnom životu. Svojim ponašanjem pravobranilac mora poslužiti kao primjer humanosti poštovanja ljudskog dostojanstva te temeljnih ljudskih vrijednosti, prava i sloboda.</p>
        <p style="text-align: center;"><strong>Član 8.</strong></p>
        <p>Pravobranioci u obavljanju poslova iz svoje nadležnosti, moraju konstantno voditi računa o dostojanstvu pravobranilačke funkcije te izbjegavati bilo kakav moguć sukob interesa koji bi bio zasnovan na rodbinskim vezama, prijateljstvima, kao i na finansijskim ili profesionalnim vezama.</p>
        <p style="text-align: center;"><strong>Član 9.</strong></p>
        <p>Pravobranioci moraju izbjegavati ne samo stvarnu neprimjerenost bilo koje vrste, nego i sve što izgleda neprimjerno u načinu na koji obavljaju svoje dužnosti. Pravobranioci su dužni da se povuku iz spora kada postoji osnovan razlog da se vjeruje da je kod njih prisutan sukob interesa.</p>
        <p style="text-align: center;"><strong>Član 10.</strong></p>
        <p>Pravobranioci su dužni kontinuirano održavati najviše standarde profesionalizma i pravne struke. Pravobranioci su dužni konstantno se usavršavati u cilju održavanja i poboljšavanja boljeg poznavanja zakonskih propisa. Od pravoranioca se očekuje da u cilju izvršavanja svojih obaveza budu uključeni u neprestano usavršavanje u oblasti prava.</p>
        <p style="text-align: center;"><strong>Član 11.</strong></p>
        <p>Pravobranioci neće pristupati nijednoj političkoj organizaciji. Pravobranioci mogu biti članovi profesionalnih i i društvenih organizacija koje nisu uključene niti podržavaju diskriminaciju po osnovu faktora koji uključuju, ali se ne ograničavaju na etničku ili nacionalnu pripadnost, spol, vjeru ili fizički nedostatak. Oni mogu biti uključeni te objavljivati radove kod akademskih ili drugih istraživačkih instititucija, u domenu svoje struke, imajući pri tm na umu prikladnost teme koja se obrađuje, način njene prezentacije, te dignitet svoje profesije.</p>
        <p style="text-align: center;"><strong>Član 12.</strong></p>
        <p>Pravobranilac se ne može baviti poslovima koji narušavaju ugled pravobranilačke profesije, takođe pravobranilac ne smije da prihvati poslove i zvanja koji bi ga doveli u podređen položaj ili naveli na izvršavanje tuđih naloga koji bi mogli uzrokovati samostalnost i neovisnost pravobranilačke funkcije.</p>
        <p style="text-align: center;"><strong>Član 13.</strong></p>
        <p>Svako ponašanje i postupanje pravobranioca suprotno principima i pravilima ovog kodeksa, predstavlja povredu pravobranilačke dužnosti te povredu pravobranilačke etike uopće.</p>
        <p style="text-align: center;"><strong>Član 14.</strong></p>
        <p>Sud časti u skladu sa statutom udruženja pravobranilaca, te pravilima pred sudom časti, odlučit će o tome da li radnja koja se stavlja na teret članu udruženja, predstavlja povredu pravila kodeksa, te će o navedenim činjenicama zauzeti stav u smislu donošenja odluke kojom se član udruženja oslobađa odgovornosti ili odluke kojom se izriče određena mjera.</p>
        <p style="text-align: center;"><strong>Član 15.</strong></p>
        <p>Nacrt kodeksa pravobranilačke etike izmjene i dopune istog, priprema i utvrđuje Predsjedništvo i upućuje na javnu raspravu članovima Udruženja. Predsjedništvo razmatra primjedbe i prijedloge koji su dati u raspravi, zauzima stavove o njima, utvrđuje prijedlog kodeksa pravobranilačke etike te izmjene i dopune i upućuje ih Skupštini na usvajanje.</p>
        <p style="text-align: center;"><strong>Član 16.</strong></p>
        <p>Ovaj Kodeks pravobranilačke etike stupa na snagu danom donošenja.</p>
        <p>Dana 30.09.2010. godine</p>
        <p style="text-align: right;"><strong>PREDSJEDNIK UDRUŽENJA</strong></p>
        <p style="text-align: right;">___________________________</p>
        <p style="text-align: right;"><strong><em>Mr. sci. Šabić Smajo</em></strong></p>
        <p style="text-align: right;">&nbsp;</p>
    </div>
</template>
    <script setup></script>
    