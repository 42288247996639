<template>
  <NavbarHome3 />
  <Breadcrumb mainTitle="Kontakt" subTitle="Kontakt" />
  <!-- Contact Area -->

  <!-- -->

  <!-- Contact Form -->

  <div id="contact-page" class="contact-section blue-bg section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 text-center wow fadeInRight">
          <div class="contact-form-wrapper">
            <div class="section-title">
              <h2>Imate upit ? <b>Kontaktirajte nas</b></h2>
            </div>
            <div class="contact-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--CTA Section-->

  <CTA />

  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import CTA from "@/components/CTA.vue";
import Footer from "@/components/Footer.vue";
import ContactForm from "@/components/ContactForm.vue";


export default {
  name: "Contact",
  components: { NavbarHome3, Breadcrumb, CTA, Footer, ContactForm },
  data() {
    return {
      contactData: [],
    };
  },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Kontakt | UPUFBiH",
  description: "Kontakt | UPUFBiH",
  ogDescription: "Kontakt | UPUFBiH",
  ogTitle: "Kontakt | UPUFBiH",
});


</script>
<style scoped>

</style>