<template>
<div class="team-area section-padding pb-150">
    <div class="container">
    <div class="row">
        <div
          class="col-lg-3 col-md-6 col-sm-6 col-12 wow my-5 pb-5"
          v-for="member in members"
          :key="member.id"
        >
          <div class="single-team-member">
            <div
              class="team-member-bg"
              :style="'background-image:url(' + member.image + ')'"
            >
              <div class="team-content">
                <div class="team-title">
                  <span>{{ member.firstName }} {{ member.lastName }}</span>
                </div>
                <div class="team-subtitle">
                  <span>{{ member.pozicija }}</span><br>
                  <span>{{ member.nazivPravobranilastva }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const members = computed(() => {
   return store.getters.members;
});
</script>