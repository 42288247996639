<template>
    <NavbarHome3 />
    <Breadcrumb mainTitle="Kodeks pravobranilačke etike" subTitle="Kodeks pravobranilačke etike" />
    <div style="margin: 100px;">
        <AboutTab3 />
    </div>
    <Footer />
  </template>
  
  <script>
  // @ is an alias to /src
  import NavbarHome3 from "@/components/NavbarHome3.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Footer from "@/components/Footer.vue";
  import AboutTab3 from "@/views/AboutTab3.vue";
  
  export default {
    name: "Kodeks",
    components: { NavbarHome3, Breadcrumb, Footer, AboutTab3 },
  
    async mounted() {
      //scrolling to top of the window
      window.scrollTo(0, 0);
    },
  };
  </script>
  <script setup>
  import { useSeoMeta } from "@vueuse/head";
  useSeoMeta({
    title: "Kodeks pravobranilačke etike | UPUFBiH",
    description: "Kodeks pravobranilačke etike | UPUFBiH",
    ogDescription: "Kodeks pravobranilačke etike | UPUFBiH",
    ogTitle: "Kodeks pravobranilačke etike | UPUFBiH",
  });
  </script>
  