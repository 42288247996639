<template>
    <div>
        <div class="about-tab-1">
            <p>Na osnovu člana 20. Statuta Udruženja pravobranilaca / pravobranitelja &nbsp;u Federaciji Bosne i Hercegovine, na prijedlog predsjedništva udruženja, na redovnoj skupštini Udruženja &nbsp;održanoj dana 30.09.2010. godine skupština udruženja pravobranilaca &nbsp;Federacije Bosne i Hercegovine donosi:</p>
            <p style="text-align: center; font-size: 25px;"><span style="color: #bea77c;"><strong>PRAVILA O POSTUPKU PRED SUDOM ČASTI<br>UDRUŽENJA PRAVOBRANILACA / PRAVOBRANITELJA<br>FEDERACIJE BOSNE I HERCEGOVINE</strong></span></p>
            <p style="text-align: center;"><strong>I. OPŠTE ODREDBE</strong></p>
            <p style="text-align: center;"><strong>1.&nbsp;</strong></p>
            <p>U cilju dosljedne primejene Kodeksa pravobranilačke etike, &nbsp;načina utvrđivanja&nbsp; odgovornosti u vezi&nbsp; povreda pravila utvrđenih pomenutim kodeksom, te narušavanja ugleda pavobranilačke funkcije od strane pravobranilaca članova udruženja, Skupština Udruženja pravobranilaca Federacije Bosne i Hercegovine utvrđuje pravila postupka pred Sudom časti Udruženja pravobranilaca / pravobranitelja Federacije Bosne i Hercegovine ( u daljem tekstu: udruženje pravobranioca).</p>
            <p style="text-align: center;"><strong>2.</strong></p>
            <p>U skladu sa članom 33.&nbsp; Statuta udruženja pravobranilaca &nbsp;Federacije Bosne i Hercegovine, Sud časti utvrđuje osnovanost navoda o učinjenenim povredama Kodeksa pravobranilačke&nbsp; etike te izriče mjere &nbsp;koje mogu biti isključenje člana udruženja pravobranioca iz udruženja &nbsp;ili &nbsp;opomena&nbsp; i to u skladu sa ovim pravilima o postupku pred Sudom časti.</p>
            <p align="center"><strong>II. POSTUPAK PRED SUDOM ČASTI</strong></p>
            <p align="center"><strong>Član 3.</strong></p>
            <p>Postupak pred sudom časti &nbsp;sprovodi se u skladu sa ovim pravilima, odredbama Statuta udruženja pravobranilaca, Kodeksom pravobranilačke &nbsp;etike, uz shodnu primjenu Zakona o krivičnom postupku.</p>
            <p style="text-align: center;"><strong>Član 4.</strong></p>
            <p>Postupak pred sudom časti&nbsp; na inicijativu članova udruženja ili inicijativu građana, pokreće Predsjednik udruženja. Prdsjednik udruženja &nbsp;&nbsp;pred sudom časti podnosi prijedlog za pokretanje postupaka uz navođenje činjenica i saznanja o mogućim kršenjima kodeksa pravobranilačke etike ili činjenica o ugrožavaju ugleda &nbsp;pravobranilačke funkcije.</p>
            <p align="center"><strong>Član 5.</strong></p>
            <p>Sud časti nakon što zaprimi prijedlog za pokretanje postupka protiv člana udruženja, zakazuje raspravu te na osnovu raspoloživih materijalnih činjenica i saznanja, otvara raspravu te po okončanju iste, &nbsp;donosi odluku o osnovanosti navoda koji upućuju na kršenje pravila definisanih kodeksom pravobranilačke etike, te osnovanosti navoda o ugrožavanju ugleda pravobranilačke funkcije.</p>
            <p style="text-align: center;"><strong>Član 6.</strong></p>
            <p>U toku postupka pred sudom časti obavezno će biti saslušan član udruženja protiv kojeg se vodi postupak. Nakon saslušanja člana udruženja i izvođenja dokaza Sud časti većinom glasova donosi odluku po dostavljenom prijedlogu.</p>
            <p align="center"><strong>Član 7</strong>.</p>
            <p>Ukoliko se član udruženja neopravdano ne odazove po uredno dostavljenom pozivu, &nbsp;Sud časti će donijeti odluku bez njegovog saslušanja na osnovu prijedloga i drugih provedenih dokaza.</p>
            <p align="center"><strong>Član 8.</strong></p>
            <p>Sud časti svojom odlukom će prijavljenog osloboditi ili oglasiti odgovornim za kršenje pravila&nbsp; utvrđenih kodeksom pravobranilačke etike &nbsp;te izreći mjeru članu udruženja protiv kojeg je vođen postupak pred sudom časti.</p>
            <p style="text-align: center;"><strong>Član 9.</strong></p>
            <p>Donesena odluka će se izraditi u roku 30 dana i dostaviti predsjedništvu udruženja &nbsp;i članu udruženja protiv koga je vođen postupak.</p>
            <p style="text-align: center;"><strong>Član 10.</strong></p>
            <p>Protiv odluke Suda časti &nbsp;član udruženja protiv koga je vođen postupak utvrđivanja odgovornosti ima pravo žalbe Skupštini udruženja,&nbsp; koja se podnosi u roku od 15 dana nakon dostavljanja odluke.</p>
            <p align="center"><strong>III PRELAZNE ZAVRŠNE ODREDBE</strong></p>
            <p align="center"><strong>Član 11.</strong></p>
            <p>Evidenciju o izrečenim mjerama članovima udruženja vodi Predsjedništvo udruženja sudija Federacije Bosne i Hercegovine.</p>
            <p style="text-align: center;"><strong>Član 12.</strong></p>
            <p>O izmjenama i dopunama ovih Pravila, na prijedlog Predsjedništva udruženja sudija odlučuje Skupština udruženja pravobranilaca Federacije Bosne i Hercegovine.</p>
            <p style="text-align: center;"><strong>Član 13.</strong></p>
            <p>Ova Pravila stupaju na snagu danom usvajanja od strane Skupštine udruženja sudija Federacije Bosne i Hercegovine.</p>
            <p style="text-align: right;"><strong>PREDSJEDNIK UDRUŽENJA</strong></p>
            <p style="text-align: right;">___________________________</p>
            <p style="text-align: right;"><strong><em>Mr. sci. Šabić Smajo</em></strong></p>
            <p style="text-align: right;">&nbsp;</p>
        </div>
    </div>
</template>

<script setup>
</script>

<style>
/* Your styles here */
</style>
