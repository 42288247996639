<template>
  <NavbarHome3 />
  <Breadcrumb mainTitle="Postani član" subTitle="Postani član" />

  <!-- Sign Up  -->

  <div class="main-wrapper gray-bg">
    <div class="image-holder">
      <img src="/assets/images/clanstvo.jpg" alt="" />
    </div>
    <div class="form-inner">
      <form @submit.prevent="save">
        <div class="form-header">
          <h3>Postani član udruženja</h3>
          <hr />
        </div>
        <div class="form-group">
          <label for="">Ime i prezime</label>
          <span v-if="formErrors.nazivPravobranilastva" class="error-message"> Obavezno polje!</span>
          <input type="text" class="form-control" v-model="memberForAdd.name" :class="{ 'error': formErrors.name }"/>
        </div>
        <div class="form-group">
          <label for="">Naziv pravobranilaštva</label>
          <span v-if="formErrors.nazivPravobranilastva" class="error-message"> Obavezno polje!</span>
          <input type="text" class="form-control" v-model="memberForAdd.nazivPravobranilastva" :class="{ 'error': formErrors.nazivPravobranilastva }" />
        </div>
        <div class="form-group">
          <label for="">Pozicija u pravobranilaštvu</label>
          <span v-if="formErrors.pozicija" class="error-message">Obavezno polje!</span>
          <input type="text" class="form-control" v-model="memberForAdd.pozicija" :class="{ 'error': formErrors.pozicija }" />
        </div>
        <div class="form-group">
          <label for="">E-mail</label>
          <span v-if="formErrors.email" class="error-message">Obavezno polje!</span>
          <input type="email" class="form-control" v-model="memberForAdd.email" :class="{ 'error': formErrors.email }" />
        </div>
        <div class="form-group">
          <label for="">Kontakt telefon</label>
          <span v-if="formErrors.phone" class="error-message">Obavezno polje!</span>
          <input type="text" class="form-control" v-model="memberForAdd.phone" :class="{ 'error': formErrors.phone }" />
        </div>

        <div class="form-group">
          <button class="main-btn">
            <span v-if="loading" class="loader-circle"></span>
            <span v-else>Pošalji prijavu</span>
          </button>
        </div>
      </form>
      <div v-if="messageSuccess || messageError" class="form-request-message" :class="{ 'color-red': messageError }">
        {{ messageSuccess || messageError }}
      </div>
    </div>
    
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Registration",
  components: { NavbarHome3, Breadcrumb, Footer },
  data() {
    return {
      memberForAdd: {
        name: "",
        nazivPravobranilastva: "",
        pozicija: "",
        email: "",
        phone: "",
      },

      formErrors: {
        name: false,
        nazivPravobranilastva: false,
        pozicija: false,
        email: false,
        phone: false,
      },

      messageSuccess: "",
      messageError: "",
    };
  },
  mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },

  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    save() {
      console.log(this.memberForAdd);
      let formData = new FormData();
      if (!this.memberForAdd.name) {
        this.formErrors.name = true;
      } else {
        formData.append("FirstName", this.memberForAdd.name.split(" ")[0]);
        formData.append("LastName", this.memberForAdd.name.split(" ")[1]);
      }
      if (!this.memberForAdd.email) {
        this.formErrors.email = true;
      } else {
        formData.append("Email", this.memberForAdd.email);
      }
      if (!this.memberForAdd.phone) {
        this.formErrors.phone = true;
      }  else {
        formData.append("Phone", this.memberForAdd.phone);
      }
      if (!this.memberForAdd.nazivPravobranilastva) {
        this.formErrors.nazivPravobranilastva = true;
      } else {
        formData.append("NazivPravobranilastva", this.memberForAdd.nazivPravobranilastva);
      }
      if (!this.memberForAdd.pozicija) {
        this.formErrors.pozicija = true;
      } else {
        formData.append("Pozicija", this.memberForAdd.pozicija);
      }
      formData.append("Password", "password");
      formData.append("UserName", this.memberForAdd.email);
      
      
      if (this.formErrors.name || this.formErrors.nazivPravobranilastva || this.formErrors.pozicija || this.formErrors.email || this.formErrors.phone) {
        this.$store.dispatch('setLoading', false);
        return;
      } else {
        formData.append("UserCreatedId", 1);
        this.$store.dispatch('setLoading', true);
        this.$store.dispatch('addMember', formData).then(() => {
          this.$store.dispatch('setLoading', false);
          this.messageSuccess = "Uspješno ste poslali zahtjev za članstvo. Naš tim će vas kontaktirati uskoro.";
        }).catch(() => {
          this.$store.dispatch('setLoading', false);
          this.messageError = "Došlo je do greške prilikom slanja zahtjeva. Molimo pokušajte ponovo.";
        })
      }
    },
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Novi član | UPUFBiH",
  description: "Novi član | UPUFBiH",
  ogDescription: "Novi član | UPUFBiH",
  ogTitle: "Novi član | UPUFBiH",
});
</script>

<style scoped>
.form-request-message {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: green;
}

.color-red {
  color: red;
}
</style>
